<template>
  <div class="full-height">
    <v-container class="pa-0 full-height">
      <div class="d-flex flex-row justify-end full-height">
        <v-sheet class="text-h1 hero-text pa-2 transparent">
          <div>
            <vue-typed-js class="text-right" :strings="['<p> Hi there! <br> Welcome to my Studio.</p>']" :contentType="'html'" :showCursor="false" :typeSpeed="75">
              <p class="typing text-right white--text"></p>
            </vue-typed-js>
          </div>
        </v-sheet>
        <div :class="$vuetify.breakpoint.mdAndUp ? 'line' : 'line_small'"></div>
        <!--      <div class="ma-0 pa-0">
                <div class="diagonal down"></div>
                <div class="diagonal up"></div>
              </div>-->
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Hero",
}

</script>

<style scoped>
.diagonal.up {
  transform:
      translateY(20px)
      translateX(5px)
      rotate(-45deg);
  width: 23vh;
  height: 30vw;
  border-right: solid 1px white;
  margin-left: -15vw;
  position: absolute;
  top: 50vh;
  left: 85vw;
}

.diagonal.down {
  transform:
      translateY(-20px)
      translateX(5px)
      rotate(45deg);
  width: 23vh;
  height: 30vw;
  border-right: solid 1px white;
  position: absolute;
  top: 50vh;
  left: 85vw;
}
</style>