<template>
    <div :class="$vuetify.breakpoint.mdAndUp ? 'full' : 'full_withoutPadding'">
        <v-container class="pa-0">
            <div class="d-flex flex-row justify-end full-height">
                <v-sheet class="text-h2 hero-text pa-2 transparent">
                    <p class="text-h2 white--text text-right low-margin">Portfolio</p>
                    <div v-if="$vuetify.breakpoint.mdAndUp">
                        <v-row>
                            <v-col cols="6" class="d-flex child-flex">
                                <v-img max-width=50vw
                                       :src="require('@/assets/portfolio/portfolio_1.jpg')"
                                       aspect-ratio="0.7"/>
                            </v-col>
                            <v-col cols="6" class="d-flex child-flex">
                                <v-img max-width=50vw
                                       :src="require('@/assets/portfolio/portfolio_2.jpg')"
                                       aspect-ratio="0.7"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="8"
                                   class="d-flex child-flex">
                                <v-img max-width=50vw
                                       :src="require('@/assets/portfolio/portfolio_3.jpg')"
                                       aspect-ratio="1.5"/>
                            </v-col>
                            <v-col cols="4"
                                   class="d-flex child-flex">
                                <v-img max-width=50vw
                                       :src="require('@/assets/portfolio/portfolio_4.jpg')"
                                       aspect-ratio="1.5"/>
                            </v-col>
                        </v-row>
<!--                        <v-row>-->
<!--                            <v-col cols="12">-->
<!--                                <v-img max-width=50vw-->
<!--                                       :src="require('@/assets/portfolio/portfolio_6.jpg')"/>-->
<!--                            </v-col>-->
<!--                        </v-row>-->
<!--                        <v-row>-->
<!--                            <v-col cols="12">-->
<!--                                <v-img max-width=50vw-->
<!--                                       :src="require('@/assets/portfolio/portfolio_7.jpg')"/>-->
<!--                            </v-col>-->
<!--                        </v-row>-->
                        <v-row>
                            <v-col cols="4"
                                   class="d-flex child-flex">
                                <v-img max-width=50vw
                                       :src="require('@/assets/portfolio/portfolio_7.jpg')"
                                       aspect-ratio="1.2"/>
                            </v-col>
                            <v-col cols="8"
                                   class="d-flex child-flex">
                                <v-img max-width=50vw
                                       :src="require('@/assets/portfolio/portfolio_8.jpg')"
                                       aspect-ratio="1.2"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-img max-width=50vw
                                       :src="require('@/assets/portfolio/portfolio_9.jpg')"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-img max-width=50vw
                                       :src="require('@/assets/portfolio/portfolio_10.jpg')"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4"
                                   class="d-flex child-flex">
                                <v-img max-width=50vw
                                       :src="require('@/assets/portfolio/portfolio_11.jpg')"
                                       aspect-ratio="0.8"/>
                            </v-col>
                            <v-col cols="8"
                                   class="d-flex child-flex">
                                <v-img max-width=50vw
                                       :src="require('@/assets/portfolio/portfolio_12.jpg')"
                                       aspect-ratio="2"/>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-if="$vuetify.breakpoint.smAndDown" style="padding-left: 50px;">
                        <v-img max-width=80vw class="padding_bottom"
                               :src="require('@/assets/portfolio/portfolio_2.jpg')"/>
                        <v-img max-width=80vw class="padding_bottom"
                               :src="require('@/assets/portfolio/portfolio_1.jpg')"/>
                        <v-img max-width=80vw class="padding_bottom"
                               :src="require('@/assets/portfolio/portfolio_3.jpg')"/>
                        <v-img max-width=80vw class="padding_bottom"
                               :src="require('@/assets/portfolio/portfolio_4.jpg')"/>
<!--                        <v-img max-width=80vw class="padding_bottom"-->
<!--                               :src="require('@/assets/portfolio/portfolio_5.jpg')"/>-->
                        <v-img max-width=80vw class="padding_bottom"
                               :src="require('@/assets/portfolio/portfolio_6.jpg')"/>
                        <v-img max-width=80vw class="padding_bottom"
                               :src="require('@/assets/portfolio/portfolio_8.jpg')"/>
                        <v-img max-width=80vw class="padding_bottom"
                               :src="require('@/assets/portfolio/portfolio_7.jpg')"/>
                        <v-img max-width=80vw class="padding_bottom"
                               :src="require('@/assets/portfolio/portfolio_9.jpg')"/>
                        <v-img max-width=80vw class="padding_bottom"
                               :src="require('@/assets/portfolio/portfolio_10.jpg')"/>
                        <v-img max-width=80vw class="padding_bottom"
                               :src="require('@/assets/portfolio/portfolio_11.jpg')"/>
                        <v-img max-width=80vw class="padding_bottom"
                               :src="require('@/assets/portfolio/portfolio_12.jpg')"/>
                    </div>
                </v-sheet>
                <div :class="$vuetify.breakpoint.mdAndUp ? 'line' : 'line_small'"></div>
            </div>
        </v-container>
    </div>
</template>

<script>
export default {
    name: "Illustration",

    methods: {
        scroll(id) {
            document.getElementById(id).scrollIntoView({
                behavior: "smooth"
            });
        }
    }
}
</script>

<style scoped>

</style>