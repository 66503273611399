import Vue from 'vue'
import VueRouter from 'vue-router'
import Hero from '@/components/Hero'
import About from "@/components/About";
import Illustration from "@/components/Illustration";
import GraphicDesign from "@/components/GraphicDesign";
import SocialMedia from "@/components/SocialMedia";
import Contact from "@/components/Contact";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Hero',
    component: Hero
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/illustration',
    name: 'Illustratoin',
    component: Illustration
  },
  {
    path: '/graphic-design',
    name: 'Graphic Design',
    component: GraphicDesign
  },
  {
    path: '/social-media',
    name: 'Social Media',
    component: SocialMedia
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
