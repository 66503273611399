<template>
  <div :class="$vuetify.breakpoint.mdAndUp ? 'full' : 'full_withoutPadding'">
    <v-container class="pa-0">
      <div class="d-flex flex-row justify-end full-height">
        <v-sheet class="text-h2 hero-text pa-2 transparent">
          <p class="text-h2 white--text">Social Media</p>
        </v-sheet>
        <div :class="$vuetify.breakpoint.mdAndUp ? 'line' : 'line_small'"></div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "SocialMedia"
}
</script>

<style scoped>

</style>