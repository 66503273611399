<template>
  <v-footer
      dark
      padless
      class="black"
  >
    <v-card
        flat
        tile
        class="transparent lighten-1 white--text text-center"
        :class="$vuetify.breakpoint.mdAndUp ? 'footer-card' : 'footer-card_withoutPadding'"
        d-flex
    >
      <v-card-text class="d-flex justify-center align-center">
        <v-img src="../assets/davien_logo.png" max-height="300" max-width="80px"></v-img>
        <div>
          <v-list justify="center" class="d-flex flex-column align-center mb-2 transparent">
            <v-list-item link href="https://www.facebook.com/DavienArt/" target="_blank">
              <v-img src="../assets/social/facebook.svg" class="icon"></v-img>
            </v-list-item>
            <v-list-item link href="https://www.instagram.com/cornelia_davien/" target="_blank">
              <v-img src="../assets/social/instagram.svg" class="icon"></v-img>
            </v-list-item>
            <v-list-item link href="https://twitter.com/Davien_art" target="_blank">
              <v-img src="../assets/social/twitter.svg" class="icon"></v-img>
            </v-list-item>
          </v-list>
        </div>
      </v-card-text>

      <v-card-text class="white--text pt-0">
      </v-card-text>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong> &copy; Cornelia Abfalter</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",

  data: () => ({
    icons: [
      'mdi-facebook',
      'mdi-twitter',
      'mdi-instagram',
    ],
  }),
}
</script>

<style scoped>
.footer-card {
  border-top: 2px solid #ffffff !important;
  width: 100%;
  margin-right: 100px;
}

.footer-card_withoutPadding {
    border-top: 2px solid #ffffff !important;
    width: 100%;
}

.icon {
  max-height: 25px;
  max-width: 25px;
}
</style>