<template>
    <div class="app">
        <v-app>
            <v-main class="hero">
                <v-app-bar color="transparent" elevation="0" dark
                           class="border_bottom"
                           clipped-right>
                    <v-row align="center">
                        <v-btn text x-large @click="scroll('illustration')">
                            Portfolio
                        </v-btn>
                        <v-btn text x-large @click="scroll('about')">
                            About
                        </v-btn>
                        <!--            <v-btn text x-large>
                                      Graphic Design
                                    </v-btn>
                                    <v-btn text x-large>
                                      Social Media
                                    </v-btn>-->
                        <v-btn text x-large @click="scroll('contact')">
                            Contact
                        </v-btn>
                    </v-row>
                    <v-spacer/>
                    <v-img v-if="$vuetify.breakpoint.mdAndUp" src="./assets/davien_logo.png" max-width="30"></v-img>
                </v-app-bar>
                <v-navigation-drawer
                        color="transparent"
                        :permanent="$vuetify.breakpoint.mdAndUp"
                        app clipped right width="100"
                        class="border_left"
                        height="100%">
                    <v-list nav dense class="d-flex flex-column align-center justify-center mb-6">
                        <v-list-item class="pa-0" link href="/">
                            <v-img src="./assets/davien_logo.png" max-height="300" max-width="80"></v-img>
                        </v-list-item>
                    </v-list>
                    <v-list justify="center" class="d-flex flex-column align-center mb-6">
                        <v-list-item link href="https://www.facebook.com/DavienArt/" target="_blank">
                            <v-img src="./assets/social/facebook.svg" class="icon ma-4"></v-img>
                        </v-list-item>
                        <v-list-item link href="https://www.instagram.com/cornelia_davien/" target="_blank">
                            <v-img src="./assets/social/instagram.svg" class="icon ma-4"></v-img>
                        </v-list-item>
                        <v-list-item link href="https://twitter.com/Davien_art" target="_blank">
                            <v-img src="./assets/social/twitter.svg" class="icon ma-4"></v-img>
                        </v-list-item>
                    </v-list>
                </v-navigation-drawer>
                <Hero/>
            </v-main>
            <Illustration id="illustration"/>
            <About id="about"/>
            <!--      <GraphicDesign/>
                  <SocialMedia/>-->
            <Contact id="contact"/>
            <Footer/>
        </v-app>
    </div>
</template>

<script>
import Hero from "@/components/Hero";
import About from "@/components/About";
import Illustration from "@/components/Illustration";
/*import GraphicDesign from "@/components/GraphicDesign";
import SocialMedia from "@/components/SocialMedia";*/
import Contact from "@/components/Contact";
import Footer from "@/components/Footer";

export default {
    name: 'App',

    components: {
        Hero,
        About,
        Illustration,
        /*GraphicDesign,
        SocialMedia,*/
        Contact,
        Footer,
    },

    data: () => ({
        //
    }),

    methods: {
        scroll(id) {
            document.getElementById(id).scrollIntoView({
                behavior: "smooth"
            });
        }
    },


};
</script>

<style scoped>
@import './assets/css/global.css';

* {
    text-transform: none !important;
}

@font-face {
    font-family: "Open Sans";
    src: local("Open Sans"),
    url(./assets/fonts/OpenSans.ttf) format("truetype");
}

@font-face {
    font-family: "Playfair Display";
    src: local("Playfair Display"),
    url(./assets/fonts/PlayfairDisplay.ttf) format("truetype");
}

.hero {
    background: url('./assets/background.jpg');
    background-size: cover;
    height: 100vh;
}

.border_bottom {
    border-bottom: 2px solid #ffffff !important;
}

.border_left {
    border-left: 2px solid #ffffff !important;
}

.icon {
    max-height: 50px;
    max-width: 50px;
}

</style>


<!--
<div id="nav">
<router-link to="/">Home</router-link> |
<router-link to="/about">About</router-link>
</div>
<router-view/>-->
