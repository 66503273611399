<template>
  <div ref="about" :class="$vuetify.breakpoint.mdAndUp ? 'full' : 'full_withoutPadding'">
    <v-container class=" pa-0">
      <div class="d-flex flex-row justify-end half-height">
        <v-sheet class="hero-text pa-2 transparent">
          <div class="d-flex flex-column justify-end">
            <span class="text-h2 white--text text-right">About Me</span>
            <p class="white--text text-h6 top-distance text-right">
                Hi, I'm Cornelia - a freelance artist and graphic designer. <br>
                I've been working in illustration for almost 6 years now and
                had plenty of opportunities to learn and grow with every project and experience.
                I've been working with big companies, authors, dreamers
                and other artists on a number of projects like Book Covers,
                Character Creation, Background Design, DnD characters and numerous more.
            </p>
          </div>
        </v-sheet>
        <div :class="$vuetify.breakpoint.mdAndUp ? 'line' : 'line_small'"></div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "About",

}
</script>

<style scoped>

.top-distance {
  margin-top: 30px;
}
</style>