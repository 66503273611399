<template>
  <div :class="$vuetify.breakpoint.mdAndUp ? 'full' : 'full_withoutPadding'">
    <v-container class="pa-0">
      <div class="d-flex flex-row justify-end half-height">
        <v-sheet class="hero-text pa-2 transparent white--text">
          <div class="d-flex flex-column justify-end text-right">
            <span class="text-h2">Contact Me</span>
            <p class="text-h6 top-distance">
                Are you interested in working with me? <br>
                Just drop me an E-Mail and we will bring your vision to life!
            </p>
            <a class="text-h5 top-distance mail"
               :href="`mailto:cornelia@studiodavien.com`"
               target="_blank">cornelia@studiodavien.com</a>
          </div>
        </v-sheet>
        <div :class="$vuetify.breakpoint.mdAndUp ? 'line' : 'line_small'"></div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Contact"
}
</script>

<style scoped>

.top-distance {
  margin-top: 30px;
}

.mail {
  text-decoration: none; color: inherit;
}
</style>