import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueTypedJs from 'vue-typed-js'
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs

Vue.config.productionTip = false

Vue.use(VueTypedJs)
Vue.use(LottieAnimation); // add lottie-animation to your global scope

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
